import React from "react";

let Workhistory = () => {
    return(
        <section>
                <div class="uk-margin-medium-top uk-margin glass-pane container uk-flex-center">
                        <div class='paragraph-center'>
                            <h2>Mural Consulting</h2>
                            <h3>Microsoft365 Escalations Engineer</h3>
                            <p>Asses multiple cases to ensure proper troubleshooting has been completed for
a customers issue and then write up documentation and then direct to internal
engineers to resolve issues updating both the customer side and the
escalations side simultaneously.</p>
                            <h3>Microsoft365 Technical Advisor Management</h3>
                            <p>In addition to maintaining my own backlog to assist my team of 9 with their
workload I was responsible for assisting with their most complex cases as well
as helping adjust their tone/technical jargon to lower level agents so that both
parties are on the same page to best assist the issue.</p>
                            <h3>Microsoft365 Technical Advisor</h3>
                            <p>Prioritize the backlog workflow of 100 tickets to my team of 20 customer
support agents. Mastered Microsoft365 Suite to identify features and direct
bugs to the proper channels. Automated a data entry pain point in PowerShell
to save 10 hours of management time a week.</p>
                        </div>
                        <div class='white-text'>
                            <h2>University of Arizona</h2>
                            <h3>Computer Science \ Javascript Full Stack Course</h3>
                        </div>
                </div>
        </section>
    )
}

export default Workhistory