import React from "react";

let Header = () => {
    return (
        <header class=''>
            <nav class="glass-pane-header">
                <div class="uk-container">
                    <div uk-navbar=''>
                        <div class="uk-navbar-center">
                            <a class="uk-navbar-item uk-logo" href="/">Jason Lore</a>
                        </div>
                    </div>
                </div>
            </nav>
        </header>
    )
}

export default Header