import React from "react";

let FooterSection = () => {
    return (
        <footer>
            <nav class="glass-pane-header">
                <div class="uk-container">
                    <div uk-navbar=''>
                        <div class="uk-navbar-center">
                            <a class="uk-navbar-item" target="_blank" rel="noreferrer" href="mailto:Jason@Offenbaker.com">Jason@Offenbaker.com</a>
                            <a class="uk-navbar-item" target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/jasonlore/">LinkedIn</a>
                            <a class="uk-navbar-item" target="_blank" rel="noreferrer" href="/Jason_Offenbaker.pdf">Resume</a>
                        </div>
                    </div>
                </div>
            </nav>
        </footer>
    )
}

export default FooterSection